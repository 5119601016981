<template>
  <WeModal v-bind:title="getOrderTitle" v-on:close="$emit('close')">
    <div slot="body" class="custom-scrollbar" style="max-width: 100%; min-height: 250px">
      <div v-if="order">
        <ul class="nav nav-pills my-3" id="pills-tab" role="tablist">
          <!-- Order Detail -->
          <li class="nav-item col-12 col-md-auto p-0" role="presentation">
            <a
              href="#order-detail"
              class="nav-link active"
              id="order-detail-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="order-detail"
              aria-selected="true"
            >
              <span class="ml-1">Sipariş Detayı</span>
            </a>
          </li>
          <!-- ./Order Detail -->

          <!-- Order Detail -->
          <li class="nav-item col-12 col-md-auto p-0" role="presentation">
            <a
              href="#invoice-detail"
              class="nav-link ml-2"
              id="invoice-detail-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="invoice-detail"
              aria-selected="true"
            >
              <span class="ml-1">Fatura & Teslimat</span>
            </a>
          </li>
          <!-- ./Order Detail -->

          <!-- Cargo Info -->
          <li class="nav-item col-12 col-md-auto p-0" role="presentation" v-if="showCargoTab">
            <a
              href="#cargo-detail"
              class="nav-link ml-2"
              id="cargo-detail-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="cargo-detail"
              aria-selected="true"
            >
              <span class="ml-1">Kargo Bilgisi</span>
            </a>
          </li>
          <!-- ./Cargo Info -->

          <!-- ERP Info -->
          <li
            class="nav-item col-12 col-md-auto p-0"
            role="presentation"
            v-if="
              erpConfig &&
              order.erp &&
              order.erp.message &&
              order.order_type !== 'İptal Edildi'
            "
          >
            <a
              href="#erp-info"
              class="nav-link ml-2"
              id="erp-info-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="erp-info"
              aria-selected="true"
            >
              <span class="ml-1">ERP</span>
            </a>
          </li>
          <!-- ERP Info -->

          <!-- Bank Result -->
          <li
            class="nav-item col-12 col-md-auto p-0"
            role="presentation"
            v-if="order.bank_result.data"
          >
            <a
              href="#bank-result"
              class="nav-link ml-2"
              id="bank-result-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="bank-result"
              aria-selected="true"
            >
              <span class="ml-1">Banka Sonuç Bilgisi</span>
            </a>
          </li>
          <!-- Bank Result -->

          <li class="nav-item ml-auto col-12 col-md-auto p-0" role="presentation">
            <span
              class="btn btn-success"
              v-tooltip.bottom="'Siparişi Başarılı Duruma Getirir'"
              v-on:click="$emit('on-accept-order', order.id)"
            >
              <i class="fas fa-check"></i> Durum Güncelle
            </span>
            <span
              class="btn btn-outline-secondary ml-2"
              v-print="printConfig"
              ref="printButton"
            >
              <i class="fas fa-print"></i> Yazdır
            </span>
          </li>
        </ul>
        <div
          class="tab-content custom-scrollbar pr-3"
          style="max-height: 400px; overflow-x: auto; width:auto"
        >
          <!-- Order Detail -->
          <div
            class="tab-pane fade active show"
            id="order-detail"
            role="tabpanel"
            aria-labelledby="order-detail-tab"
          >
            <DetailTab  v-bind:order="order" />
          </div>
          <!-- ./Order Detail -->

          <!-- Address -->
          <div
            class="tab-pane fade"
            id="invoice-detail"
            role="tabpanel"
            aria-labelledby="invoice-detail-tab"
          >
            <InvoiceTab v-bind:order="order" />
          </div>
          <!-- ./Address -->

          <!-- Cargo Info -->
          <div
            class="tab-pane fade"
            id="cargo-detail"
            role="tabpanel"
            aria-labelledby="cargo-detail-tab"
          >
            <div class="card-body">
              <div class="col-12 col-lg-5 pl-lg-0 mb-3">
                <WeInput
                  class="mb-3 mb-lg-0"
                  v-model="order.cargoDetail.package_quantity"
                  v-bind:filter-number="true"
                  label="Koli Miktarı"
                  name="package-quantity"
                />
              </div>
              <div class="col-12 col-lg-5 pl-lg-0 mb-3">
                <label for="cargo-id" class="custom-label">Kargo Firması</label>
                <v-select
                  id="cargo-id"
                  v-model="order.cargoDetail.cargo"
                  v-bind:options="cargo.firmList"
                  v-bind:clearable="false"
                  label="name"
                >
                </v-select>
              </div>
              <div
                class="col-12 col-lg-5 pl-lg-0 mb-3"
                v-if="
                  order.cargoDetail &&
                  order.cargoDetail.cargo &&
                  order.cargoDetail.cargo.is_insurance
                "
              >
                <WeInput
                  class="mb-3 mb-lg-0"
                  v-model="order.cargoDetail.product_type"
                  label="Ürün Tipi"
                  name="product-type"
                />
              </div>
              <div class="col-12 col-lg-5 pl-lg-0 mb-3">
                <label for="cargo-id" class="custom-label"
                  >Kargo Takip Numarası</label
                >
                <WeInput
                  class="mb-3 mb-lg-0"
                  type="text"
                  id="cargo-id"
                  v-model="cargoTrackingNumber"
                />
              </div>
              <div
                class="col-12 col-lg-5 pl-lg-0"
                v-if="
                  order.cargoDetail &&
                  order.cargoDetail.cargo
                "
              >
                <div class="row justify-content-between mb-3">
                  <div class="col-auto" v-if="order.cargoDetail.barcode_url">
                    <a
                      v-bind:href="order.cargoDetail.barcode_url"
                      target="_blank"
                      class="btn btn-sm btn-outline-secondary"
                      ><i class="fas fa-print"></i> Barkod Yazdır</a
                    >
                  </div>
                  <div class="col-auto">
                    <span
                      class="btn btn-sm btn-outline-danger"
                      v-on:click="onSubmitCargoDetail"
                      ><i class="fas fa-sync"></i> {{ getBarcodeText }}</span
                    >
                  </div>
                  <div class="col-12 mt-2">
                    <button
                      class="btn btn-sm btn-info"
                      v-on:click.prevent="$emit('on-update-info', order)"
                    >
                      Kargo Bilgisi Güncelle
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="text-left"
                v-if="
                  order.cargoDetail.cargo &&
                  !order.cargoDetail.cargo.is_integration
                "
              >
                <span class="btn btn-success" v-on:click="onSubmitCargoDetail"
                  ><i class="fas fa-sync"></i> Güncelle</span
                >
              </div>
            </div>
          </div>
          <!-- Cargo Info -->

          <div
            class="tab-pane fade"
            id="erp-info"
            role="tabpanel"
            aria-labelledby="erp-info-tab"
          >
            <Erp v-bind:erp="order.erp" />
          </div>
          <div
            class="tab-pane fade"
            id="bank-result"
            role="tabpanel"
            aria-labelledby="bank-result-tab"
          >
            <BankResult v-bind:result="order.bank_result" />
          </div>
        </div>
        <!-- Print All -->
        <div v-bind:id="printConfig.id" class="print">
          <DetailTab
            v-bind:order="order"
            class="mb-3"
            v-bind:site-logo="siteLogo"
          />
          <InvoiceTab v-bind:order="order" />
        </div>
        <!-- Print All -->
      </div>
      <!-- Order Detail -->

      <WeLoading v-else />
    </div>
  </WeModal>
</template>
<script>
import { mapState } from "vuex";
import DetailTab from "./views/DetailTab/Index";
import InvoiceTab from "./views/InvoiceTab/Index";
import Erp from "./views/Erp/Index";
import BankResult from "./views/BankResult/Index";

export default {
  name: "OrderDetail",
  data() {
    return {
      printConfig: {
        id: "printOrder",
        popTitle: "Sipariş Detayı",
      },
    };
  },
  components: {
    DetailTab,
    InvoiceTab,
    Erp,
    BankResult,
  },
  props: {
    order: {
      default: () => {},
    },
    showModal: {
      default: false,
    },
    erpConfig: {
      default: false,
    },
  },
  methods: {
    onSubmitCargoDetail() {
      this.$emit("on-update-cargo");
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
      cargo: (state) => state.shared.cargoCompanies,
    }),
    siteLogo() {
      return this.config["site.logo"] || null;
    },
    cargoTrackingNumber: {
      get() {
        return this.order.cargoDetail.cargo_tracking_number || null;
      },
      set(value) {
        this.order.cargoDetail.cargo_tracking_number = value;
      },
    },
    getBarcodeText() {
      return this.order.cargoDetail.barcode_url
        ? "Barkod Yenile"
        : "Barkod Oluştur";
    },
    showCargoTab() {
      return (
        this.order.cargoDetail &&
        (this.order.cargoDetail.cargo ||
          this.order.cargoDetail.cargo_tracking_number ||
          this.order.cargoDetail.barcode_url)
      );
    },
    getOrderTitle() {
      const order = this.order;
      if (order) {
        const orderNo = order.order_no;
        const memberName = order.customer.name
        return `${orderNo} - ${memberName}`;
      }
    },
  },
};
</script>
<style lang="scss">
@media print {
  .print {
    .card {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      border: none !important;
      .card-header {
        padding-left: 0 !important;
        .p-0 {
          display: none;
        }
      }
      .card-body {
        padding: 0 !important;
      }
    }
    .table {
      th,
      td {
        font-size: 16px !important;
        padding: 3px !important;
      }
    }
    .products-table {
      th {
        text-align: center !important;
      }
    }
  }
}
</style>
